/* eslint-disable */
function replaceLoginErrorMessage() {
  var search = location.search.replace(/^\?/, '').split('&');
  var hasErrorMsgFlag = false;
  if (search && search.map) {
    search.map(function (elem) {
      if (0 === elem.indexOf('login_error_message=')) {
        hasErrorMsgFlag = true;
        var msg = decodeURIComponent(elem.replace(/^login_error_message\=/, ''));
        alert(msg);
      }
    });
  }

  if (hasErrorMsgFlag) {
    location.replace(location.href.replace(/&*\?*login_error_message\=[^&]+/, ''));
  }
}

replaceLoginErrorMessage();
